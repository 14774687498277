import React, { FC } from 'react';

import { ColorVariant } from '@constants/constants';

import s from './Header.module.scss';

interface HeaderProps {
  colorVariant: ColorVariant;
  isMainHeader?: boolean;
}

export const Header: FC<HeaderProps> = ({ children, colorVariant, isMainHeader }) => {

  if (isMainHeader) {
    return <h1 className={`${s.header} ${s[colorVariant]} ${s.header__main}`}> {children} </h1>;
  } else {
    return <h2 className={`${s.header} ${s[colorVariant]}`}> {children} </h2>;
  }

};
