import React from 'react';
import s from './PublishedDate.module.scss';
import {formatDate} from '@helpers/date.helper';
import {useIntl} from "gatsby-plugin-intl";

interface IPublishedDate {
  publishedDate: string;
  isAuthorAvailable: boolean;
}

const PublishedDate = ({publishedDate, isAuthorAvailable}: IPublishedDate) => {

  const intl = useIntl();
  
  return (
    <div className={`${s.publishedDate} ${isAuthorAvailable ? s.center : s.left}`}>
      {intl.formatMessage({id: 'post__published'})} {formatDate(publishedDate)}
    </div>
  )
}

export default PublishedDate;
