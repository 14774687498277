import React from 'react';

// import { AlgoliaSearch } from '+shared/algolia-search/AlgoliaSearch.component';

import {ColorVariant} from '@constants/constants';

import s from './Hero.module.scss';
import {Header} from '../header/Header.component';
import {useIntl} from 'gatsby-plugin-intl';
import {StaticImage} from 'gatsby-plugin-image'
import {AlgoliaSearch} from '../../shared/components/algolia-search/AlgoliaSearch.component';


export const Hero = () => {

  // const {t} = useTranslation('knowledgeBase');
  const intl = useIntl();

  return (
    <section className={s.hero}>
      <StaticImage src="../../../assets/images/hero-bg-darker.jpg"
                   alt=""
                   layout={'constrained'}
                   className={s.hero__image}
      />
      <div className={s.hero__content}>
        <Header isMainHeader colorVariant={ColorVariant.white}>
          <div className={s.hero__header} dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'knowledgeBase__header'})}}/>
        </Header>
        <AlgoliaSearch />
      </div>
    </section>
  );
};
