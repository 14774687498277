import React from 'react';
// import { useTranslation } from 'next-i18next';
// import Link from 'next/link';
// import { useRouter } from 'next/router';

// import { ORIGINAL_ROUTE } from '+helpers/routing';

import Parents from '@assets/svg/Parents.svg';
import Grandparents from '@assets/svg/Grandparents.svg';
import Medicals from '@assets/svg/Medicals.svg';
import multilingualSlugs from '../../../intl/slugs.json';

const LOCALE_FR = 'fr';

import s from './Categories.module.scss';
import {useIntl} from 'gatsby-plugin-intl';
import {Link} from 'gatsby';
import {PageType} from "@constants/pageType.constants";

export const Categories = () => {
  const intl = useIntl();

  return (
    <div className={s.categories}>
      <Link to={'.' + multilingualSlugs[intl.locale][PageType.CATEGORY_PARENTS]}
            className={`${s.categories__category} ${intl.locale === LOCALE_FR && s.categories__between}`}>
        <div className={s.categories__icon}>
          <Parents className={s.categories__iconSvg}/>
        </div>
        <p className={`${s.categories__title} ${intl.locale === LOCALE_FR && s.categories__titleTop}`}
           dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'category__parentsCategory'})}}/>
      </Link>
      <Link to={'.' + multilingualSlugs[intl.locale][PageType.CATEGORY_GRANDPARENTS]}
            className={`${s.categories__category} ${intl.locale === LOCALE_FR && s.categories__between}`}>
        <div className={s.categories__icon}>
          <Grandparents className={s.categories__iconSvg}/>
        </div>
        <p className={`${s.categories__title} ${intl.locale === LOCALE_FR && s.categories__titleTop}`}
           dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'category__grandparentsCategory'})}}/>
      </Link>
      <Link to={'.' + multilingualSlugs[intl.locale][PageType.CATEGORY_MEDICINE]}
            className={`${s.categories__category} ${intl.locale === LOCALE_FR && s.categories__between}`}>
        <div className={s.categories__icon}>
          <Medicals className={s.categories__iconSvg}/>
        </div>
        <p className={`${s.categories__title} ${intl.locale === LOCALE_FR && s.categories__titleTop}`}
           dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'category__medicalsCategory'})}}/>
      </Link>
    </div>
  );
};
