import React from 'react';
import s from './SearchResults.module.scss';
import {useIntl} from 'gatsby-plugin-intl';
import {AlgoliaPostRecord} from '../../../../../lib/algolia/algolia.types';
import {useLocalizedDynamicRoute} from '../../../../../hooks/use-localized-dynamic-route';
import {Link} from 'gatsby';
import {PageType} from '@constants/pageType.constants';

interface ISearchResults {
  hits: AlgoliaPostRecord[];
}

export const SearchResults = ({hits}: ISearchResults) => {
  const intl = useIntl();
  const link = useLocalizedDynamicRoute();

  return (
    <div className={s.searchResults}>
      <div className={s.searchResults__label}>
        <span>{intl.formatMessage({id: 'common__algoliaResults'})}</span>
      </div>
      {
        hits.length === 0
          ? <span
            className={s.searchResults__noResults}>{intl.formatMessage({id: 'common__algoliaNoResultsFound'})}</span>
          :
          hits.map(hit => (
            <Link to={link(PageType.KNOWLEDGE_BASE_ARTICLES, hit.slug)}>
              <div key={hit.objectID} className={s.searchResults__hit}>
                <p className={s.searchResults__title}>{hit.title}</p>

                <span className={s.searchResults__readMore}>{intl.formatMessage({id: 'common__algoliaRead'})}</span>
              </div>
            </Link>
          ))
      }
    </div>
  );
};

