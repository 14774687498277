import CoverDE from '@assets/images/BW-link-cover-DE.jpg';
import CoverENG from '@assets/images/BW-link-cover-ENG.jpg';
import CoverFR from '@assets/images/BW-link-cover-FR.jpg';
import CoverPL from '@assets/images/BW-link-cover-PL.jpg';

export const coversDictionary: { [key: string]: string } = {
  'de': CoverDE,
  'en': CoverENG,
  'fr': CoverFR,
  'pl': CoverPL
};
