export const guaranteeDayNumber = 28;

export const customMonths: {[key: number]:string} = {
  0: '01',
  1: '02',
  2: '03',
  3: '04',
  4: '05',
  5: '06',
  6: '07',
  7: '08',
  8: '09',
  9: '10',
  10: '11',
  11: '12',
};

const prepareDayFormt = (day: number) => {
  if(day < 10){
    return `0${day}`
  }

  return day;
};

export const getFormatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getUTCMonth();
  const day = date.getDate();

  return `${year}-${customMonths[month]}-${prepareDayFormt(day)}`;
};

export const getDatesInRange = (startDate: Date, endDate: Date) => {
  let dates:string[] = [];
  const theDate = new Date(startDate);
  while (theDate < endDate) {
    const newDate = new Date(theDate);

    dates = [...dates, getFormatDate(newDate)];
    theDate.setDate(theDate.getDate() + 1)
  }
  dates = [...dates, getFormatDate(endDate)];

  return dates
};

export const comparePostsDateAsc = (postA: { node: { date: string | number | Date; } }, postB: { node: { date: string | number | Date; } }) => {
  const aMilliseconds = new Date(postA.node.date).getTime();
  const bMilliseconds = new Date(postB.node.date).getTime();

  if (bMilliseconds > aMilliseconds) {
    return 1;
  } else if (aMilliseconds > bMilliseconds) {
    return -1;
  }

  return 0;
};

export const formatDate = (dateValue: string) => new Date(dateValue).toLocaleDateString('tr-TR')
