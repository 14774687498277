import React from 'react';

import s from './Banner.module.scss';
import {BREAKPOINT, isMobileResolution} from '@helpers/layout.helper';
import {useWindowSize} from '../../../hooks/use-window-size';
import {WpBanner} from '../../../types/wordpress.types';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

interface IBanner {
  banner: WpBanner;
}

export const Banner = (props: IBanner) => {
  const windowSize = useWindowSize();
  const isMobile = isMobileResolution(windowSize, BREAKPOINT.TABLET);
  
  const banner = props.banner.edges[0].node.bannerProperties;

  const image = getImage(banner.bannerImage.localFile.childImageSharp.gatsbyImageData)
  const imageMobile = getImage(banner.bannerMobileImage.localFile.childImageSharp.gatsbyImageData)
  const url = banner.bannerUrl;
  
  return (
    <>
      {
        isMobile ?
          <div className={s.banner__mobile}>
            <a href={url}>
              <GatsbyImage alt={''} image={imageMobile}
                className={s.banner__mobileImage}
              />
            </a>
          </div> :
          <div className={s.banner}>
            <a href={url}>
              <GatsbyImage alt={''} image={image}
                className={s.banner__image}
              />
            </a>
          </div>
      }
    </>
  );
};
