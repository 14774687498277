import React from 'react';
import {useIntl} from 'gatsby-plugin-intl';
import {Hero} from '../../app/knowledge-base/hero/Hero.component';
import {Categories} from '../../app/knowledge-base/categories/Categories.component';
import {graphql} from 'gatsby';
import {Banner} from '../../app/knowledge-base/banner/Banner.component';
import {Teasers} from '../../app/knowledge-base/teasers/Teasers.component';
import {PostGrid} from '../../app/knowledge-base/post-grid/PostGrid.component';
import {TemplateHelper} from '@helpers/templates.helper';
import {Button} from '@components/button/Button';
import s from "../../app/knowledge-base/teasers/components/teaser/Teaser.module.scss";
import multilingualSlugs from "../../intl/slugs.json";
import {PageType} from "@constants/pageType.constants";
import { StoreSearch } from '@components/store-search/StoreSearch.component';
import { getLink } from '@helpers/linking.helper';
import { ThumbnailsHelper } from '@helpers/thumbnails.helper';
import { coversDictionary } from '@helpers/covers.helper';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { useLocalizedDynamicRoute } from '../../hooks/use-localized-dynamic-route';
import { useSiteMetadata } from '../../hooks/use-static-metadata';

export const comparePostsDateAsc = (postA: { node: { date: string | number | Date; } }, postB: { node: { date: string | number | Date; } }) => {
  const aMilliseconds = new Date(postA.node.date).getTime();
  const bMilliseconds = new Date(postB.node.date).getTime();

  return Math.sign(aMilliseconds - bMilliseconds);

};

const Center = ({children}) => {
  return (
    <div style={{textAlign: 'center'}}>
      {children}
    </div>
  )

}
const KnowledgeBaseTemplate = (props) => {

  const {
    featuredPosts,
    featuredMedical,
    lastArticlesWithoutMedicine,
    lastGuidesWithoutMedicine,
    lastVideosWithoutMedicine,
    medicalVideos,
    medicalArticles,
    medicalGuides,
    bannerA,
    bannerB,
    goodToKnow,
    tutorials
  } = props.data


  const lastPostsWithoutMedicine = [
    ...lastArticlesWithoutMedicine.edges,
    ...lastVideosWithoutMedicine.edges,
    ...lastGuidesWithoutMedicine.edges,
  ];
  
  console.log('medical articles', ...medicalArticles.edges)

  const latestMedicalPosts = [
    ...medicalVideos.edges,
    ...medicalArticles.edges,
    ...medicalGuides.edges,
  ]

  const intl = useIntl();
  const POSTS_LIMIT = 4;

  const pinned = featuredPosts?.edges[0].node.featuredDontMissArticlesInHomePage.articlesDontMissHp;
  const pinnedIds = pinned.map(el => el.id);

  const pinnedMedical = featuredMedical.nodes[0].featuredMedicalArticles.articlesForMedical;
  const pinnedMedicalIds = pinnedMedical.map(el => el.id);

  const latest = lastPostsWithoutMedicine
    .map(TemplateHelper.getNode)
    .flat(Infinity)
    .sort(TemplateHelper.sortByDatePosts)
    .filter(post => !pinnedIds.includes(post.id))
    .slice(0, POSTS_LIMIT - pinned.length);


  const latestMedical = latestMedicalPosts
    .map(TemplateHelper.getNode)
    .flat(Infinity)
    .sort(TemplateHelper.sortByDatePosts)
    .filter(post => !pinnedMedicalIds.includes(post.id))
    .slice(0, POSTS_LIMIT - pinnedMedical.length);

  const dontMissPosts = [...pinned, ...latest]
  const medicalPosts = [...pinnedMedical, ...latestMedical]

  const link = useLocalizedDynamicRoute();
  const { siteUrl } = useSiteMetadata();
  
  return (
    <>
      <GatsbySeo
        htmlAttributes={{
          language: intl.locale,
        }}
        languageAlternates={[
          {
            hrefLang: 'x-default',
            href: `https://avionaut.com${getLink(intl.locale, PageType.KNOWLEDGE_BASE)}/`,
          },
          {
            hrefLang: 'en',
            href: `https://avionaut.com${getLink('en', PageType.KNOWLEDGE_BASE)}/`,
          },
          {
            hrefLang: 'pl',
            href: `https://avionaut.com${getLink('pl', PageType.KNOWLEDGE_BASE)}/`,
          },
          {
            hrefLang: 'de',
            href: `https://avionaut.com${getLink('de', PageType.KNOWLEDGE_BASE)}/`,
          },
          {
            hrefLang: 'cz',
            href: `https://avionaut.com${getLink('cz', PageType.KNOWLEDGE_BASE)}/`,
          },
          {
            hrefLang: 'fr',
            href: `https://avionaut.com${getLink('fr', PageType.KNOWLEDGE_BASE)}/`,
          },
        ]}
        canonical={siteUrl+link(PageType.KNOWLEDGE_BASE)+'/'}
        title={intl.formatMessage({ id: 'common__knowledgeBaseTitle' })}
        description={intl.formatMessage({ id: 'common__knowledgeBaseDescription' })}
        metaTags={[
          {
            property: 'thumbnail',
            content: ThumbnailsHelper[intl.locale],
          },
          {
            property: 'og:title',
            content: intl.formatMessage({ id: 'common__knowledgeBaseTitle' }),
          },
          {
            property: 'og:image',
            content: coversDictionary[intl.locale],
          },
          {
            property: 'og:description',
            content: intl.formatMessage({ id: 'common__knowledgeBaseDescription' }),
          },
          {
            property: 'twitter:title',
            content: intl.formatMessage({ id: 'common__knowledgeBaseTitle' }),
          },
          {
            property: 'twitter:description',
            content: intl.formatMessage({ id: 'common__knowledgeBaseDescription' }),
          },
        ]}
      />
      <Hero/>
      <Categories/>

      <PostGrid posts={dontMissPosts} header={intl.formatMessage({id: 'common__dontMiss'})}/>

      <Center>
        <Button href={
          multilingualSlugs[intl.locale][PageType.KNOWLEDGE_BASE] +
          multilingualSlugs[intl.locale][PageType.ARTICLE]
        }
        >
          {intl.formatMessage({id: 'common__moreArticles'})}
        </Button>
      </Center>

      <Banner banner={bannerA}/>

      <PostGrid posts={medicalPosts} header={intl.formatMessage({id: 'common__medical'})}/>
      <Center>
        <Button href={
          multilingualSlugs[intl.locale][PageType.KNOWLEDGE_BASE] +
          multilingualSlugs[intl.locale][PageType.CATEGORY_MEDICINE]
        }
        >
          {intl.formatMessage({id: 'common__moreArticles'})}
        </Button>
      </Center>
      <Banner banner={bannerB}/>

      <Teasers
        smallTeasersSet={goodToKnow}
        bigTeasersSet={tutorials}
      />
      <StoreSearch
        header={intl.formatMessage({ id: 'product__storeSearchHeader' })}
        fieldText={intl.formatMessage({ id: 'product__storeSearchPlaceholder' })}
        shopsURL={getLink(intl.locale, PageType.SHOPS)}
        currentLocale={intl.locale}
        isBackgroundVisible
      />
    </>
  );
};

export const query = graphql`
  query GetAllFeaturedDontMissArticlesInHomePage($locale: String) {
    lastVideosWithoutMedicine: allWpVideo(
      sort: {fields: date, order: DESC}
      limit: 4
      filter: {
        locale: {locale: {eq: $locale}}
        categories: {nodes: {elemMatch: {slug: {nin: ["medicine", "medecine", "medizin", "medycyna"]}}}}
      }
    ){
      edges {
        node {
          ...VideoInfo
          ...VideoCategories
          ...VideoImage
        }
      }
    }
    lastArticlesWithoutMedicine: allWpArticle(
      sort: {fields: date, order: DESC}
      limit: 4
      filter: {
        locale: {locale: {eq: $locale}}
        categories: {nodes: {elemMatch: {slug: {nin: ["medicine", "medecine", "medizin", "medycyna"]}}}}
      }
    ) {
      edges {
        node {
          ...ArticleInfo
          ...ArticleCategories
          ...ArticleImage
        }
      }
    }
    lastGuidesWithoutMedicine: allWpGuide(
      sort: {fields: date, order: DESC}
      limit: 4
      filter: {
        locale: {locale: {eq: $locale}}
        categories: {nodes: {elemMatch: {slug: {nin: ["medicine", "medecine", "medizin", "medycyna"]}}}}
      }
    ) {
      edges {
        node {
          ...GuideInfo
          ...GuideCategories
          ...GuideImage
        }
      }
    }
    featuredPosts: allWpFeaturedDontMissArticleInHomePage(
      sort: {fields: date, order: DESC}
      filter: {locale: {locale: {eq: $locale}}}
    ) {
      edges {
        node {
          id
          featuredDontMissArticlesInHomePage {
            articlesDontMissHp {
              ... on WpArticle {
                ...ArticleInfo
                ...ArticleCategories
                ...ArticleImage
              }
              ... on WpVideo {
                ...VideoInfo
                ...VideoCategories
                ...VideoImage
              }
              ... on WpGuide {
                ...GuideInfo
                ...GuideCategories
                ...GuideImage
              }
            }
          }
        }
      }
    }
    bannerA: allWpBanner(filter: {slug: {eq: "banner-a"}, locale: {locale: {eq: $locale}}}) {
      edges {
        node {
          id
          bannerProperties {
            bannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            bannerMobileImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            bannerUrl
          }
        }
      }
    }
    medicalVideos: allWpVideo(
      filter: {locale: {locale: {eq: $locale}}, categories: {nodes: {elemMatch: {slug: {in: ["medicine", "medecine", "medizin", "medycyna", "medicinske"]}}}}}
    ) {
      edges {
        node {
          ...VideoInfo
          ...VideoCategories
          ...VideoImage
        }
      }
    }
    medicalArticles: allWpArticle(
      filter: {locale: {locale: {eq: $locale}}, categories: {nodes: {elemMatch: {slug: {in: ["medicine", "medecine", "medizin", "medycyna", "medicinske"]}}}}}
    ) {
      edges {
        node {
          ...ArticleInfo
          ...ArticleCategories
          ...ArticleImage
        }
      }
    }
    medicalGuides: allWpGuide(
      filter: {locale: {locale: {eq: $locale}}, categories: {nodes: {elemMatch: {slug: {in: ["medicine", "medecine", "medizin", "medycyna", "medicinske"]}}}}}
    ) {
      edges {
        node {
          ...GuideInfo
          ...GuideCategories
          ...GuideImage
        }
      }
    }
    featuredMedical: allWpFeaturedMedicalsArticle(filter: {locale: {locale: {eq: $locale}}}) {
      nodes {
        featuredMedicalArticles {
          articlesForMedical {
            ... on WpArticle {
              ...ArticleInfo
              ...ArticleCategories
              ...ArticleImage
            }
            ... on WpGuide {
              ...GuideInfo
              ...GuideCategories
              ...GuideImage
            }
            ... on WpVideo {
              ...VideoInfo
              ...VideoCategories
              ...VideoImage
            }
          }
        }
      }
    }
    bannerB:   allWpBanner(filter: {slug: {eq: "banner-b"}, locale: {locale: {eq: $locale}}}) {
      edges {
        node {
          id
          bannerProperties {
            bannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            bannerMobileImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            bannerUrl
          }
        }
      }
    }
    goodToKnow: allWpGuide(
      limit: 6
      filter: {locale: {locale: {eq: $locale}}}
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          ...GuideInfo
        }
      }
    }
    tutorials: allWpVideo(
      limit: 3
      filter: {locale: {locale: {eq: $locale}}}
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          ...VideoInfo
          ...VideoImage
          videoContent {
            videoYoutube
          }
        }
      }
    }
  }

`

export default KnowledgeBaseTemplate;
