import React, { useEffect, useRef, useState } from 'react';

import { SearchIndex } from 'algoliasearch';

import Search from '../../../../assets/svg/search-2.svg';

import s from './AlgoliaSearch.module.scss';
import {useOutsideClick} from '../../../../hooks/use-click-outside';
import {useIntl} from 'gatsby-plugin-intl';
import {LocaleKnowledgebase} from '@helpers/locale.knowledgebase';
import {getSearchIndexInSpecificLocale} from '../../../../lib/algolia/algolia-search';
import {AlgoliaPostRecord} from '../../../../lib/algolia/algolia.types';
import {SearchResults} from './search-result/SearchResults.component';

export const AlgoliaSearch = () => {
  const intl = useIntl();

  const [searchIndex, setSearchIndex] = useState<SearchIndex>(getSearchIndexInSpecificLocale(intl.locale as LocaleKnowledgebase));
  const [hits, setHits] = useState<AlgoliaPostRecord[]>([]);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const refClickableArea = useRef<HTMLDivElement>(null);

  useOutsideClick(refClickableArea, () => setIsInputFocused(false));

  const search = (value: string) => {
    if (value === '') {
      setHits([]);
      return;
    }

    searchIndex
      .search(value)
      .then(res => {
        if (res.hits) {
          setHits(res.hits as AlgoliaPostRecord[]);
        }
      });
  };

  useEffect(() => {
    setSearchIndex(getSearchIndexInSpecificLocale(intl.locale as LocaleKnowledgebase));
  }, [intl.locale]);

  return (
    <div className={s.algolia}>
      <div className={s.algolia__placeholder} ref={refClickableArea}>
        <input
          className={s.algolia__input}
          type={'text'}
          onChange={e => {
            e.target.value.length > 0 ? setIsInputFocused(true) : setIsInputFocused(false);
            search(e.target.value);
          }}
          placeholder={intl.formatMessage({ id: 'common__algoliaPlaceholder' })}
          onFocus={(e) => {
            e.target.value.length > 0 ? setIsInputFocused(true) : setIsInputFocused(false);
          }}
        />
        <div className={s.algolia__button}>
          <Search className={s.algolia__buttonSvg} />
        </div>
        {
          isInputFocused &&
          <div className={s.algolia__results}>
            <SearchResults hits={hits} />
          </div>
        }
      </div>
    </div>
  );
};
