import algoliasearch from 'algoliasearch';
import { AlgoliaIndex } from './config';
import {LocaleKnowledgebase} from '@helpers/locale.knowledgebase';

export const algoliaSearchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_PUBLIC_APP_ID ? process.env.GATSBY_ALGOLIA_PUBLIC_APP_ID : '',
  process.env.GATSBY_ALGOLIA_PUBLIC_SEARCH_API_KEY ? process.env.GATSBY_ALGOLIA_PUBLIC_SEARCH_API_KEY : '',
);

export const getSearchIndexInSpecificLocale = (locale: LocaleKnowledgebase) => algoliaSearchClient.initIndex(AlgoliaIndex[locale]);
